
import Button from '@/components/Button.vue'
import { editCommission } from '@/modules/trade/trade'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheEditCommissionButton',
  components: { Button },
  setup () {
    const [submit, progress] = editCommission()

    return {
      submit,
      progress,
    }
  },
})
