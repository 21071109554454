
import Button from '@/components/Button.vue'
import Order from '@/modules/trade/Order'
import { TradeDirection } from '@/types'
import { computed, defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'TheCommissionDirection',
  components: { Button },
  setup () {
    const model = inject('order') as Order
    const isBuy = computed(() => model.type.value === TradeDirection.BUY)

    return {
      isBuy,
      buy () {
        model.type.value = TradeDirection.BUY
      },
      sell () {
        model.type.value = TradeDirection.SELL
      },
    }
  },
})
