
import Button from '@/components/Button.vue'
import Line from '@/components/Line.vue'
import QuoteBar from '@/components/QuoteBar.vue'
import TheCommissionDirection from '@/modules/trade/components/TheCommissionDirection.vue'
import TheEditCommissionButton from '@/modules/trade/components/TheEditCommissionButton.vue'
import TheTradeCost from '@/modules/trade/components/TheTradeCost.vue'
import TheTradeLimits from '@/modules/trade/components/TheTradeLimits.vue'
import TheTradeMethod from '@/modules/fastTrade/components/TheTradeMethod.vue'
import TheTradeProducts from '@/modules/trade/components/TheTradeProducts.vue'
import TheTradeTargetPrice from '@/modules/trade/components/TheTradeTargetPrice.vue'
import Order from '@/modules/trade/Order'
import { calcProduct } from '@/modules/trade/trade'
import { Commission, readCommission, readProduct } from '@/modules/trade/trade.api'
import HoldOn from '@/provider/HoldOn.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { TradeDirection, YesOrNo } from '@/types'
import { mul } from 'essential/tools/math'
import { defineComponent, provide, shallowRef } from 'vue'

const dynamicSetting = {
  [TradeDirection.BUY]: {
    label: 'BUY',
    themeClass: 'success',
  },
  [TradeDirection.SELL]: {
    label: 'SELL',
    themeClass: 'danger',
  },
}

export default defineComponent({
  name: 'Edit',
  components: {
    TheCommissionDirection,
    HoldOn,
    TheEditCommissionButton,
    Button,
    TheTradeLimits,
    Line,
    TheTradeCost,
    TheTradeMethod,
    TheTradeTargetPrice,
    TheTradeProducts,
    PageWithHeader,
    QuoteBar,
  },
  props: {
    code: {
      type: String,
      required: true,
    },
    orderId: String,
  },
  setup (props) {
    const order = shallowRef<Commission | null>(null)
    const model = new Order(TradeDirection.BUY)
    const often = [1, 10, 20, 50, 100, 200, 500, 1000]

    readCommission({
      id: props.orderId,
    })
      .then(commission => {
        model.type.value = commission.type
        model.orderId.value = commission.orderId
        model.entrustPosition.value = commission.entrustPrice
        model.takeProfit.value = Number(commission.stopProfitPoint)
        model.stopLoss.value = Number(commission.stopLossPoint)
        model.useCoupon.value = commission.isJuan === YesOrNo.YES
        model.trailingStop.value = commission.isTrackSt === YesOrNo.YES
        model.offset.value = commission.offset

        readProduct({ codes: commission.productId })
          .then((product: any) => {
            const dot = Number(String(commission.orderNumber).substring(0, 1))
            const mulNum = Math.pow(10, commission.orderNumber.toString().length - 1)
            const curProduct = calcProduct(mulNum, dot, product)
            curProduct.isOften = often.indexOf(mul(mulNum, dot)) > -1 ? YesOrNo.YES : YesOrNo.NO
            model.updateProduct(curProduct)
            model.updateProductLeverage({ lever: commission.lever, choosed: 1, creditChoosed: 1 })
            model.updateProductQuantity(Number(commission.orderNumber).toString())
            order.value = commission
          })
      })

    const themeClass = dynamicSetting[1].themeClass

    provide('themeClass', themeClass)
    provide('order', model)

    return {
      order,
    }
  },
})
